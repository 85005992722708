@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

// module

.admin-inbox-editor {
    border-top: 1px solid $light;
}

.admin-inbox-editor__compose {
    padding-right: 12px;
    padding-left: 72px;
    
    .admin-module__footer {
        margin-top: 24px;
    }
    
}

