@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

@mixin field-range-track() {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 1px;
    margin: 0;
}

@mixin field-range-thumb() {
    width: 18px;
    height: 18px;
    background-color: $blue;
//    border: 2px solid $blue;
    border-radius: 9px;
    cursor: pointer;
//      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */

}

.admin-field.field--range {
    position: relative;
    width: 100%;
    
    padding-right: 100px;
    
    output {
        position: absolute;
        right: 0;

        width: 100px;
    	padding: 0 6px;

    	font-family: $font-sans;
        font-size: 16px;
    	font-weight: 400;
    	line-height: 24px;
    	color: #222;
    }

    input[type=range] {
        -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
        background: transparent; /* Otherwise white in Chrome */
        width: 100%; /* Specific width is required for Firefox. */
        margin: 0;
    }
    
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
    }
    
    input[type=range]:focus {
        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }
    
    input[type=range]::-ms-track {
        width: 100%;
        cursor: pointer;
        
        /* Hides the slider so custom styles can be added */
        background: transparent; 
        border-color: transparent;
        color: transparent;
    }
    
    // TRACK

    input[type=range]::-webkit-slider-runnable-track {
        @include field-range-track();
    }
    
    input[type=range]:focus::-webkit-slider-runnable-track {
        //  background-color: $grey;
    }
    
    input[type=range]::-moz-range-track {
        @include field-range-track();
    }
    
    input[type=range]::-ms-track {
        @include field-range-track();
    }

    input[type=range]::-ms-fill-lower {
    }

    input[type=range]:focus::-ms-fill-lower {
    }

    input[type=range]::-ms-fill-upper {
    }

    input[type=range]:focus::-ms-fill-upper {
        background: #367ebd;
    }
    
    // THUMB

    /* Special styling for WebKit/Blink */
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include field-range-thumb();
        
        margin-top: -8px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }
    
    /* All the same stuff for Firefox */
    input[type=range]::-moz-range-thumb {
        @include field-range-thumb();
    }
    
    /* All the same stuff for IE */
    input[type=range]::-ms-thumb {
        @include field-range-thumb();
    }    


}