.nav-status {
    display: inline-block;
    white-space: nowrap;
    position: relative;

	font-size: 14px;
	line-height: 18px;

    .status__icon {
        display: inline-block
    }
    
    .status__date {
        display: inline-block;
        
        &:before {
            content: ' – ';
        }
    
    }
  
    .status__message {
        display: inline-block;
    
        &:before {
            content: ' – ';
        }

    }

}