@import "./mixins/reset";
@include reset();

::-webkit-scrollbar { 
    display: none; 
}

body {
    scrollbar-width: none;
    -ms-overflow-style: none;
}