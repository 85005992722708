@import "../../resources/sass/settings/all";
@import "../../resources/sass/mixins/all";

.admin-finder__tabs {
    margin-top: 12px;
    margin-bottom: 12px;
    
    [role="tab"] {

        &[aria-selected="true"] {
        }
        
    }
    
    [role="tabpanel"] {
        
        &[aria-selected="false"] {
            display: none;
        }
        
    }
    
    .nav-tabs {
        margin: -6px;
    }
    
}

.admin-finder__tabpanel {
    @include pie-clearfix();
    margin-top: 12px;
    margin-bottom: 12px;
}