@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-document {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.admin-document__sidebar {
    position: absolute;

    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    width: 200px;

    .nav-menu {
        position: absolute;
        z-index: 1;
        padding-top: 24px;
        padding-right: 8px;
        padding-left: 24px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: scroll;    
    }

    + .admin-document__content {
        padding-left: 200px;
    }
  
}

.admin-document__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
} 

