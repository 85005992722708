@import "../../resources/sass/mixins/all";
@import "../../resources/sass/settings/all";

.admin-overlay {
	position: absolute;
	z-index: 4;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	background-color: rgba(black, 0.5);


	&[aria-expanded="false"] {
		opacity: 0;
		pointer-events: none;
	}

}